@import 'ngx-datatable.theme';
@import 'header-top.theme';
@import 'tgss-calendar.theme';
@import 'hopscotch.theme';

@mixin color-classes($theme) {
  $color: map-get($theme, color);
  $foreground: map-get(map-get($theme, color), foreground);
  $text-color: map-get($foreground, text);


  $background: map-get($theme, background);
  $primary: map-get(map-get($color, primary), main);
  $accent: map-get(map-get($color, accent), main);
  $warn: map-get(map-get($color, warn), main);
  $unselected-chip: map-get($background, 'unselected-chip');

  
  
  // Body color
  color: $text-color;
  
  .text-primary {
    color: $primary;
  }
  .text-accent {
    color: $accent;
  }
  .text-warn {
    color: $warn;
  }
  .mat-bg-primary {
    background-color: $primary;
  }
  .mat-bg-accent {
    background-color: $accent;
  }
  .mat-bg-warn {
    background-color: $warn;
  }
  .mat-bg-chip {
    background-color: $unselected-chip;
  }
  .mat-bg-card {
    background-color: map-get($background, card);
  }
  .mat-bg-default {
    background-color: map-get($background, background);
  }
}

@mixin tgss-theme($theme) {
  @include typography-color($theme);
  @include color-classes($theme);

  @include ngx-datatable-theme($theme);
  @include tgss-calendar($theme);
  @include hopscotch($theme);
  @include header-top($theme);
}