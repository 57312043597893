.signup4-wrap {
    background: linear-gradient(253.88deg, rgba(52, 13, 115, 0.77) -1.75%, rgba(0, 0, 0, 0.38) 100.44%),
        url('../../../../../assets/images/login-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: flex-end !important;
}
.signup4-employee {
    background: linear-gradient(253.88deg, rgba(52, 13, 115, 0.77) -1.75%, rgba(0, 0, 0, 0.38) 100.44%),
        url('../../../../../assets/images/emp-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: flex-end !important;
}

.nbfc{
    background: linear-gradient(253.88deg, rgba(52, 13, 115, 0.77) -1.75%, rgba(0, 0, 0, 0.38) 100.44%),
    url('../../../../../assets/images/login-nbfc.png') !important;
}
.nbfc-employee{
    background: linear-gradient(253.88deg, rgba(52, 13, 115, 0.77) -1.75%, rgba(0, 0, 0, 0.38) 100.44%),
    url('../../../../../assets/images/login-banner.png') !important;
}
.signup4-container {
    box-shadow: none !important;
    position: relative;
    overflow: hidden;
    margin-right: 150px;
}

.signup4-header {
    background: #1e1e1e;
    position: relative;
    padding: 35px;

    &>div {
        z-index: 2;
    }
}

.signup4-header::before {
    // background-color: rgba(3, 143, 222, 0.7);
    content: '';
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
}

.signup4-small-image {
    height: 24px;
}

.signup-content {
    background: #fff;
    border-radius: 12px;
}

.signup4-form {
    padding: 35px;
    padding-top: 0 !important;
    width: 100%;

    h2 {
        font-size: 26px;
        font-weight: 700;
        margin-bottom: 23px;
        text-align: center;
    }

    mat-label {
        font-size: 18px;
        font-weight: 700;
        font-family: 'Roboto' !important;
    }
}

.forgot-password {
    text-align: right;
    margin-top: 20px;

    a {
        color: #245883;
        font-size: 16px;
        font-weight: 500;
    }
}

.forgot-password a:hover {
    color: #2b7cbf !important;
}

.signup4-button {
    text-align: center;

    .mat-raised-button {
        border-radius: 18px;
    }
}

.ml-20 {
    margin-left: 20px;
}

.neo-logo{
    margin-top: 20px;
   text-align: center;

    img{
        width: 65%;
        padding-bottom: 4px;
    }
    p{
        color: #333;
    }
}
.signin-button{
    color: white !important;
    background: #040404 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-size: 20px;
    height: 46px;
    width: 100%;
}
.w-100{
    width: 100%;
    text-align: center;
    margin-top: 10px;
}