.header-topnav {
  position: sticky;
  margin: 0;
  padding: 10px 20px;
  width: 100%;
  top: 0;
  z-index: 999;
  height: 80px;
  background-color: #1877f2 !important;
  .container {
    padding: 0;
  }
  .topbar-branding {
    float: left;
    height: 56px;
    padding: 8px;
    margin: 0 8px;
    img {
      height: 100%;
      width: auto;
    }
  }
  .topnav {
    display: flex;
    align-items: center;
    color: #fff;
    margin-top: 8px;
  }
  .header-topnav-right {
    float: right;
    height: 48px;
    display: flex;
    align-items: center;
    padding-right: 0.67rem;
  }
}

@media (max-width: 959px) {
  .header-topnav-right {
    position: absolute;
    right: 6px;
    top: 0;
  }
}

[dir="rtl"] {
  .header-topnav {
    .topnav {
      flex-direction: row-reverse;
    }
  }
}
.trim-text-user-name{
  max-width: 195px;
  overflow: hidden;
  text-overflow: ellipsis;
}
