@mixin header-top($theme) {
  $text-color: map-get(map-get(map-get($theme, color), foreground), text);
  $background: map-get($theme, background);
  $background-card: map-get($background, card);
  $background-default: map-get($background, background);
  // @debug map-get(map-get($theme, color), foreground);
  
  .header-topnav, .topnav ul ul {
    background-color: $background-card;
  }
  .topnav ul li ul li:hover, .topnav ul li ul li {
    background: #1877f2;
    transition: 0.30s all ease-in-out;
  }
  .header-topnav a {
    color:#E6E6E6 !important;
    font-size: 18px !important;
  }

}

.topnav ul li ul li:hover, .topnav ul li ul li.open:hover{

  background: #fff !important;

}

.topnav ul li ul li a:hover, .topnav ul li ul li.open a:hover{

  color: #1877f2 !important;

}

.topnav ul li ul li .is_active_list{
  background: #fff !important;
  color: #1877f2 !important;
}